<template>
    <div class="article-answer-how-much">
        <p>
            You can get started with a 100% free account, which gives you access to a huge number of free online lessons.
            Upgrade to an Access All Areas membership for £10.99 per month or £99.99 per year to unlock all the
            features of an Access All Areas Membership. Start with a 10-day free trial. Cancel any time.
        </p>
    </div>
</template>

<script>
export default {
    name: 'ArticleAnswerHowMuch',
};
</script>
